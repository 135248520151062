<script>
import Horizontal from "./horizontal";
import ax from '@/helpers/restful/service';
import CourseFormButton from "@/components/course-form-button.vue";

export default {
    components: {
        Horizontal,
        "course-form-button" : CourseFormButton,
    },
    data() {
        return {
            loadedHeight: window.innerHeight * 0.1,
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid,
            courses: [],
            loaded: false,
        };
    },
    computed: {},
    mounted() {
        document.body.classList.add('authentication-bg');
        console.log("uid:", this.uid);
        this.get_my_courses();
    },
    methods: {
        async create_new_course() {
            const resp = await ax.post("/create-new-course", {
                uid: this.uid,
            });

            if(resp.status != 200){
                console.info("Can not load course_id:", this.course_id);
                return;
            }
            console.log("resp:", resp);

            await this.get_my_courses();

            return;
        },
        async get_my_courses() {
            this.loaded = false;
            const resp = await ax.post("/get-my-courses", {
                uid: this.uid,
            });

            if(resp.status != 200){
                console.info("Can not load course_id:", this.course_id);
                return;
            }
            console.log("resp:", resp);
            
            this.courses = resp.data.courses || [];
            this.loaded = true;
            return;
        },
    },
    watch: {
    }, 
};
</script>

<template>
    <div>
        <Horizontal layout="horizontal">
            <div class="card py-4">
                <div class="row">
                    <div class="col-12 me-3 pe-5 text-end">
                        <button class="btn btn-lg btn-success me-2" type="button" @click="get_my_courses">โหลดใหม่</button>
                        <button class="btn btn-lg btn-primary" type="button" @click="create_new_course">สร้างเนื้อหาใหม่</button>
                    </div>
                </div>
            </div>
            <div>
                <div class="row" v-if="loaded">
                    <div v-if="courses.length > 0">
                        <div v-for="(course, idx) of courses" :key="idx">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <course-form-button :course_id="course.course_id" :reloadFunction="get_my_courses"></course-form-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-else-if="courses.length == 0">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h5>ยังไม่ได้สร้างเนื้อหาใดๆ</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                           
            </div>
        </Horizontal>
    </div>
</template>
